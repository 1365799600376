import { IconClose } from "../../icons";
import React, { useEffect, useState, useRef } from "react";
// eslint-disable-next-line no-unused-vars
import style from "./Color.scss";
import { AppContext, Selector } from "..";
import { hexColor } from "../../utils/colors";
import { useMediaQuery } from "react-responsive";

export const Color = (props) => {
  const { hex, slideTo, title, images, c, m, y, k, r, g, b, darkText } = props;
  const [opened, setOpened] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });

  const refColor = useRef(null);

  useEffect(() => {
    if (opened !== "init") return;
    if (isDesktopOrLaptop) {
      slideTo();
    } else {
      refColor.current.scrollIntoView();
      window.scrollBy(0, -80);
    }
  }, [opened]);

  return (
    <AppContext.Consumer>
      {({ currentColor }) => {
        if (hexColor(currentColor.id) === hexColor(hex) && !opened) {
          setOpened("init");
        }

        return (
          <div
            className={`horizontal-child color ${
              opened ? "color-opened horizontal-child-scrollable " : ""
            } ${darkText ? "u-color-black" : ""}`}
            style={{ backgroundColor: hex }}
            onClick={() => {
              if (slideTo) slideTo();
              setOpened(true);
              currentColor.open(hex);
            }}
            ref={refColor}
          >
            <div className="color-title-sticky-wrapper">
              <div className="color-title u-px-sm u-py-md flex-alignCenter-fullHeight">
                <div className="color-icon ">
                  <Selector hex={hex} />
                </div>
                <h3 className={`color-twisted`}>{title}</h3>
                {opened && (
                  <IconClose
                    className="icon icon-close icon-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpened(false);
                      currentColor.close(hex);
                    }}
                  />
                )}
              </div>
            </div>
            <section className={`u-p-md u-pr_md-lg color-detail`}>
              {isDesktopOrLaptop && <h2 className="u-mb-xs">{title}</h2>}
              <div className="u-mb-md u-pb-md flex-flexWrap">
                <p className="no-copy u-mr-sm typo-uppercase">{hex}</p>
                <p className="no-copy u-mr-sm">
                  C:{c}, M:{m}, Y:{y}, K:{k}
                </p>
                <p className="no-copy">
                  R:{r}, G:{g}, B:{b}
                </p>
              </div>
              {images.map((image, index) => (
                <div
                  className={`color-image-text u-pb-lg flex-alignBottom-${
                    image.position === "right" ? "reverse" : ""
                  }${image.size === "large" ? "-flexWrap" : ""}`}
                  key={`${index}-${image.title}`}
                >
                  <img
                    className={`color-image-${image.size}`}
                    src={image.src}
                    alt={image.title}
                  />
                  <div
                    className={`color-text ${
                      image.size === "large" ? "u-pt-md" : "u-px-md"
                    } ${image.position === "right" ? "color-text-right" : ""}`}
                  >
                    <h3 className="captionTitle u-mb-xs">{image.title}</h3>
                    <p className="colorDescription">{image.description}</p>
                  </div>
                </div>
              ))}
            </section>
          </div>
        );
      }}
    </AppContext.Consumer>
  );
};
