import React, { useEffect, useState } from "react";
import { SelectedInfo, ExtendedLink } from "..";
// eslint-disable-next-line no-unused-vars
import style from "./Selected.scss";
import { IconClose, IconDownload, IconLink } from "../../icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Pdf } from "../Pdf/Pdf";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

const generatePdfDocument = async (selectedColors) => {
  const blob = await pdf(<Pdf selectedColors={selectedColors} />).toBlob();
  saveAs(blob, "colorselector");
};
const ExportShare = (props) => {
  const { onExport } = props;
  const [urlCopied, setUrlCopied] = useState(false);

  useEffect(() => {
    if (!urlCopied) {
      return;
    }
    setTimeout(() => {
      setUrlCopied(false);
    }, 5000);
  }, [urlCopied]);

  return (
    <div className=" flex-spaceBetween-alignCenter ">
      <p className="captionDescription">Export and share your palette</p>

      <div className="flex">
        <div className="u-px-sm">
          <ExtendedLink onClick={onExport}>
            <IconDownload className="icon" />
          </ExtendedLink>
        </div>
        <div className="u-px-sm">
          {urlCopied ? (
            <span>Copied!</span>
          ) : (
            <CopyToClipboard
              text={window.location}
              onCopy={() => setUrlCopied(true)}
            >
              <IconLink className="icon" />
            </CopyToClipboard>
          )}
        </div>
      </div>
    </div>
  );
};

export const CustomForm = ({ status, message, onValidated, onExport }) => {
  let email;
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );

  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  useEffect(() => {
    if (isLoggedIn) return;
    if (status === "success" || status === "error") {
      localStorage.setItem("isLoggedIn", "true");
      setIsLoggedIn("true");
    }
  }, [status, isLoggedIn]);

  return (
    <div className={`status-${status} ${isLoggedIn ? "status-loggedIn" : ""}`}>
      {(status === "success" || status === "error" || isLoggedIn) && (
        <ExportShare onExport={onExport} />
      )}
      <div className="selected-form ">
        <p className="captionDescription typo-uppercase u-mb-sm">
          Would like to receive your palette and full images content ?
        </p>
        <div className="flex-spaceBetween ">
          <input
            ref={(node) => (email = node)}
            type="email"
            className="selected-form-field"
            placeholder="Enter your email address"
          />
          <button className="button" onClick={submit}>
            Yes please !
          </button>
        </div>
      </div>
    </div>
  );
};

export const Selected = (props) => {
  const { onClearselected, onClose, title, selectedColors, isOpen } = props;
  const [infoDisplayed, setInfoDisplayed] = useState(selectedColors[0]);

  useEffect(() => {
    if (isOpen) setInfoDisplayed(selectedColors[0]);
  }, [selectedColors]);

  if (!isOpen || !selectedColors.length) return null;

  return (
    <div
      className={`modale-full flex-alignCenter-justifyCenter ${
        isOpen ? "modale-open" : ""
      }`}
    >
      <div className="modale-bg" onClick={onClose}></div>
      <div className="modale-container">
        <div className="flex-spaceBetween-alignCenter  u-p-md">
          <div className="flex-alignCenter">
            <h3 className="selected-title">{title}</h3>
            <button className="selected-clear" onClick={onClearselected}>
              clear all
            </button>
          </div>
          <button onClick={onClose}>
            <IconClose className="icon icon-sm" />
          </button>
        </div>
        <div className="flex">
          {selectedColors &&
            selectedColors.map((color, index) => (
              <button
                className="modale-color-preview"
                style={{ backgroundColor: color.hex }}
                key={`modale-color-preview-${index}`}
                onClick={() => {
                  setInfoDisplayed(color);
                }}
              ></button>
            ))}
        </div>
        {infoDisplayed && <SelectedInfo color={infoDisplayed} />}
        <div className="u-p-sm  ">
          <div>
            <MailchimpSubscribe
              url="https://color-selector.us7.list-manage.com/subscribe/post?u=386db4f637493d72aeae10a6f&amp;id=1a9f585ee3"
              render={({ subscribe, status, message }) => (
                <div>
                  <CustomForm
                    status={status}
                    message={message}
                    onValidated={(formData) => subscribe(formData)}
                    onExport={() => {
                      generatePdfDocument(selectedColors);
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
