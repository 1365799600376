import React from "react";

export const IconInsta = (props) => {
  return (
    <div {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12C0 5.3726 5.3726 0 12 0C18.6274 0 24 5.3726 24 12C24 18.6274 18.6274 24 12 24C5.3726 24 0 18.6274 0 12ZM12.0006 5.6C10.2625 5.6 10.0443 5.6076 9.36165 5.63865C8.6803 5.66985 8.21525 5.77775 7.8083 5.936C7.38735 6.09945 7.0303 6.31815 6.67455 6.674C6.31855 7.02975 6.09985 7.3868 5.93585 7.8076C5.7772 8.2147 5.6692 8.6799 5.63855 9.36095C5.608 10.0437 5.6 10.2619 5.6 12C5.6 13.7382 5.60775 13.9557 5.63865 14.6383C5.67 15.3197 5.77785 15.7847 5.936 16.1917C6.0996 16.6126 6.31825 16.9697 6.67415 17.3255C7.02975 17.6815 7.3868 17.9007 7.8075 18.0641C8.2147 18.2224 8.6799 18.3302 9.3611 18.3615C10.0438 18.3925 10.2618 18.4002 11.9998 18.4002C13.7381 18.4002 13.9556 18.3925 14.6383 18.3615C15.3196 18.3302 15.7851 18.2224 16.1924 18.0641C16.6132 17.9007 16.9697 17.6815 17.3253 17.3255C17.6813 16.9697 17.9 16.6126 18.064 16.1919C18.2213 15.7848 18.3293 15.3196 18.3613 14.6385C18.392 13.9558 18.4 13.7382 18.4 12C18.4 10.2619 18.392 10.0438 18.3613 9.3611C18.3293 8.67975 18.2213 8.2147 18.064 7.80775C17.9 7.3868 17.6813 7.02975 17.3253 6.674C16.9693 6.318 16.6133 6.09935 16.192 5.936C15.7839 5.77775 15.3186 5.66985 14.6373 5.63865C13.9546 5.6076 13.7373 5.6 11.9986 5.6H12.0006Z"
          fill="#F5F5E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4264 6.75334C11.5968 6.75309 11.787 6.75334 12.0006 6.75334C13.7094 6.75334 13.9119 6.75949 14.5867 6.79014C15.2107 6.81869 15.5494 6.92294 15.775 7.01054C16.0737 7.12654 16.2866 7.26519 16.5105 7.48919C16.7345 7.71319 16.8732 7.92654 16.9894 8.22524C17.077 8.45054 17.1814 8.78924 17.2098 9.41324C17.2405 10.0879 17.2472 10.2906 17.2472 11.9986C17.2472 13.7066 17.2405 13.9093 17.2098 14.5839C17.1813 15.2079 17.077 15.5466 16.9894 15.7719C16.8734 16.0706 16.7345 16.2833 16.5105 16.5072C16.2865 16.7312 16.0738 16.8698 15.775 16.9858C15.5497 17.0738 15.2107 17.1778 14.5867 17.2064C13.9121 17.237 13.7094 17.2437 12.0006 17.2437C10.2916 17.2437 10.0891 17.237 9.41444 17.2064C8.79044 17.1776 8.45174 17.0733 8.22605 16.9857C7.92735 16.8697 7.71399 16.731 7.48999 16.507C7.26599 16.283 7.12734 16.0702 7.01109 15.7714C6.92349 15.5461 6.81909 15.2074 6.79069 14.5834C6.75999 13.9087 6.75389 13.7061 6.75389 11.997C6.75389 10.2879 6.75999 10.0863 6.79069 9.41164C6.81919 8.78764 6.92349 8.44894 7.01109 8.22334C7.12709 7.92469 7.26599 7.71134 7.48999 7.48734C7.71399 7.26334 7.92735 7.12469 8.22605 7.00839C8.45164 6.92039 8.79044 6.81639 9.41444 6.78774C10.0048 6.76109 10.2336 6.75309 11.4264 6.75174V6.75334ZM15.4169 7.81599C14.9929 7.81599 14.6489 8.15964 14.6489 8.58374C14.6489 9.00774 14.9929 9.35174 15.4169 9.35174C15.8409 9.35174 16.1849 9.00774 16.1849 8.58374C16.1849 8.15974 15.8409 7.81599 15.4169 7.81599ZM12.0006 8.71334C10.1855 8.71334 8.71389 10.185 8.71389 12C8.71389 13.8151 10.1855 15.2861 12.0006 15.2861C13.8157 15.2861 15.2867 13.8151 15.2867 12C15.2867 10.185 13.8157 8.71334 12.0006 8.71334Z"
          fill="#F5F5E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0007 9.8667C13.1788 9.8667 14.134 10.8218 14.134 12C14.134 13.1782 13.1788 14.1334 12.0007 14.1334C10.8224 14.1334 9.86731 13.1782 9.86731 12C9.86731 10.8218 10.8224 9.8667 12.0007 9.8667Z"
          fill="#F5F5E9"
        />
      </svg>
    </div>
  );
};
