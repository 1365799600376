import React, { useState, useEffect } from "react";
import {
  About,
  Banner,
  AppContext,
  Panel,
  Index,
  Nav,
  Selected,
  Policy,
  Loader,
  Slider,
} from "./components";
import { useLocation, useHistory } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import styles from "./styles/app.scss";
import { deconstructUrl, constructUrl } from "./utils/query";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const App = () => {
  const [errors, setErrors] = useState(false);
  const [colors, setColors] = useState([]);
  const [content, setContent] = useState({});
  const [legal, setLegal] = useState({});
  const [colorsFetched, setColorsFetched] = useState(false);
  const [contentFetched, setContentFetched] = useState(false);
  const [legalFetched, setLegalFetched] = useState(false);
  const [currentColor, setCurrentColor] = useState("");
  const [currentPanel, setCurrentPanel] = useState("");
  const [selectedColors, setSelectedColors] = useState([]);
  const [backToStart, setBackToStart] = useState(false);
  // STATE URL
  const query = useQuery();
  useEffect(() => {
    const { selectedColors, currentColor, currentPanel } = deconstructUrl(
      query
    );
    setSelectedColors(selectedColors);
    setCurrentColor(currentColor);
    setCurrentPanel(currentPanel);
  }, []);
  // STATE URL

  // DATA
  const fetchData = async () => {
    const dataColors = await fetch("http://api.color-selector.com/colors");
    const content = await fetch("http://api.color-selector.com/siteContent");
    const legal = await fetch("http://api.color-selector.com/legal");

    dataColors
      .json()
      .then((dataColors) => {
        setColors(dataColors.data);
        setColorsFetched(true);
      })
      .catch((err) => setErrors(err));
    content
      .json()
      .then((content) => {
        setContent(content.data[0]);
        setContentFetched(true);
      })
      .catch((err) => setErrors(err));
    legal
      .json()
      .then((legal) => {
        setLegal(legal.data[0]);
        setLegalFetched(true);
      })
      .catch((err) => setErrors(err));
  };
  useEffect(() => {
    if (colorsFetched || contentFetched || legalFetched) {
      return;
    }
    fetchData();
  }, [colorsFetched, contentFetched]);
  // DATA

  // CONTEXT
  const defaultAppContext = {
    currentPanel: {
      id: currentPanel,
      open: (id) => {
        setCurrentPanel(id);
      },
      close: (id) => {
        setCurrentPanel("");
      },
    },

    currentColor: {
      id: currentColor,
      open: (id) => {
        setCurrentColor(id.replace("#", ""));
        //setOpenedColor(id);
      },
      close: (id) => {
        setCurrentColor("");
        //setOpenedColor("");
      },
    },
    selectedColors: {
      list: selectedColors,
      add: async (id) => {
        if (selectedColors.includes(id)) return;
        await setSelectedColors((oldArray) => [...oldArray, id]);
      },
      remove: async (id) => {
        if (!selectedColors.includes(id)) return;

        await setSelectedColors((oldArray) =>
          oldArray.filter((arrayId) => arrayId !== id)
        );
      },
    },
  };
  // CONTEXT

  // HISTORY
  const history = useHistory();
  useEffect(() => {
    history.push({
      search: constructUrl({ selectedColors, currentPanel, currentColor }),
    });
  }, [selectedColors, currentColor, currentPanel]);
  // HISTORY

  if (!colorsFetched || !contentFetched) return <Loader />;
  if (errors)
    return (
      <div className="u-p-md">
        <h1 className="u-mb-md">
          Something wrong happened, please try again later
        </h1>
        <p>{errors}</p>
      </div>
    );
  return (
    <AppContext.Provider value={defaultAppContext}>
      {content && colors && (
        <>
          <Nav
            {...content.home}
            openPanel={setCurrentPanel}
            onBackToStart={setBackToStart}
          />

          <Selected
            {...content.palette}
            onClose={() => setCurrentPanel("")}
            onClearselected={() => {
              setSelectedColors([]);
              setCurrentPanel("");
            }}
            isOpen={currentPanel === "your-palette"}
            selectedColors={colors.filter(
              (color) =>
                color.hex ===
                selectedColors.find(
                  (selectedColor) => selectedColor === color.hex
                )
            )}
          />
          <Slider
            colors={colors}
            content={content}
            openPanel={setCurrentPanel}
            backToStart={backToStart}
            onBackToStartDone={setBackToStart}
          />

          <Panel
            isOpen={currentPanel === "about"}
            onClose={() => setCurrentPanel("")}
          >
            <About {...content.about} />
          </Panel>
          <Panel
            isOpen={currentPanel === "policy"}
            onClose={() => setCurrentPanel("")}
          >
            <Policy {...legal.policy} />
          </Panel>
          <Panel
            isOpen={currentPanel === "cookie"}
            onClose={() => setCurrentPanel("")}
          >
            <Policy {...legal.cookie} />
          </Panel>
          <Panel
            isOpen={currentPanel === "index"}
            onClose={() => setCurrentPanel("")}
          >
            <Index
              {...content.index}
              filterAction={(colors) => {
                setColors(colors);
              }}
              colors={colors}
            />
          </Panel>
          <Banner openPanel={setCurrentPanel} />
        </>
      )}
    </AppContext.Provider>
  );
};
export default App;
