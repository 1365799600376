import React from "react";
import { IconLogo, IconBookmarksFilled } from "../../icons";
import { AppContext, ExtendedLink } from "..";
// eslint-disable-next-line no-unused-vars
import style from "./Nav.scss";

export const Nav = (props) => {
  const { openPanel, onBackToStart } = props;
  //const [selected, setSelected] = useState(false);
  return (
    <AppContext.Consumer>
      {({ currentPanel, selectedColors }) => (
        <header className=" nav">
          <ExtendedLink
            className="nav-logo"
            onClick={() => {
              openPanel("");
              onBackToStart(true);
            }}
          >
            <IconLogo />
          </ExtendedLink>

          <nav className="nav-links">
            <a
              href="https://www.instagram.com/colorselector_igy"
              target="_blank"
              className="nav-link"
            >
              insta
            </a>
            <ExtendedLink
              onClick={() => openPanel("about")}
              className={`nav-link ${
                currentPanel.id === "about" ? "current" : ""
              }`}
            >
              about
            </ExtendedLink>
            <ExtendedLink
              onClick={() => openPanel("index")}
              className={`nav-link ${
                currentPanel.id === "index" ? "current" : ""
              }`}
            >
              index
            </ExtendedLink>
            <button
              onClick={() => {
                if (selectedColors.list.length > 0) openPanel("your-palette");
              }}
            >
              <IconBookmarksFilled
                className="icon"
                number={selectedColors.list.length}
              />
            </button>
          </nav>
        </header>
      )}
    </AppContext.Consumer>
  );
};
